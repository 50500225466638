import React from 'react';

class DreamworldView extends React.Component {
    componentDidMount() {
        window.location.replace('https://tixr.com/pr/sidechain/29380')
    }
    render() {
        return (<div />);
    }
}

export default DreamworldView;
