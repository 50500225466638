import React from 'react';

class NotFoundView extends React.Component {
    componentDidMount() {
        window.location.href = 'https://stargatenft.com';
    }
    render() {
        return (<div />);
    }
}

export default NotFoundView;
