/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6169b9578f2d107096bf51c1").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6169b9578f2d108e8ebf51c2'
    htmlEl.dataset['wfSite'] = '6169b9578f2d107096bf51c1'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/stargate-4ef0f0.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page_wrapper">
              <div className="w-embed">
                <style dangerouslySetInnerHTML={{__html: "\n@media only screen and (min-width: 1440px) {\n     .af-view {font-size: 14.4px;}\n}\n" }} />
                <style dangerouslySetInnerHTML={{__html: "\n@media only screen and (min-width: 992px) and (max-width: 1200px)  {\n     .af-view .af-class-nav_link {font-size: 9.96px;}\n}\n" }} />
              </div>
              <div data-animation="over-right" data-collapse="small" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar w-nav">
                <div className="af-class-container af-class-is--nav w-container">
                    <a href="./" aria-current="page" className="af-class-brand w-nav-brand w--current"><img src="images/nav-logo.png" loading="lazy" alt className="af-class-logo" /></a>
                      <nav role="navigation" className="af-class-nav_menu w-nav-menu">
                        <a href="//mint.stargatenft.com/">
                            <button class="button"
                                style={{
                                    fontSize: 12.5,
                                    fontWeight: 'bold',
                                    fontStyle: 'italic',
                                    color: 'black',
                                    backgroundColor: '#f0c44c',
                                    borderRadius: 50,
                                    paddingBlockStart: 7,
                                    paddingBlockEnd: 7,
                                    paddingLeft: 13,
                                    paddingRight: 13
                                }}>
                            MINT YOUR NFT
                            </button>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#stargate" className="af-class-nav_link w-nav-link">STARGATE</a>
                        <a href="#benefits" className="af-class-nav_link w-nav-link">BENEFITS</a>
                        <a href="#roadmap" className="af-class-nav_link w-nav-link">ROADMAP</a>
                        <a href="#team" className="af-class-nav_link w-nav-link">TEAM</a>
                        <a href="#faq" className="af-class-nav_link af-class-is--last w-nav-link">FAQ</a>
                        <a href="//discord.gg/uErVGvQZt8" className="af-class-social_link af-class-is--nav w-inline-block"><img src="images/discord-logo.png" loading="lazy" alt className="af-class-social_icon" /></a>
                        <a href="//twitter.com/StargateDAO" className="af-class-social_link af-class-is--nav w-inline-block"><img src="images/twitter-logo.png" loading="lazy" alt className="af-class-social_icon" /></a>
                      </nav>
                  <div className="af-class-menu-button w-nav-button">
                    <div className="w-icon-nav-menu" />
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--hero"><img src="images/hero-image-min.jpg" loading="eager" style={{opacity: 0}} data-w-id="1a999894-d468-b81a-5fb8-3cfc0d99ac71" srcSet="images/hero-image-min-p-1080.jpeg 1080w, images/hero-image-min-p-1600.jpeg 1600w, images/hero-image-min-p-2000.jpeg 2000w, images/hero-image-min.jpg 2294w" sizes="(max-width: 479px) 96vw, (max-width: 767px) 95vw, (max-width: 991px) 89vw, 80vw" alt className="af-class-hero_image" /></div>
              </div>
              <div id="stargate" className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--about">
                  <div className="af-class-grid af-class-is--about">
                    <div data-w-id="d71e0daf-b539-deb5-c9af-269c36a30ee9" style={{opacity: 0}} className="af-class-column af-class-is--about-content">
                      <div className="af-class-is_22pxbp">
                        <h1>THE STARGATE - UTILITY FIRST</h1>
                      </div>
                      <p>The Stargate is your digital and physical homebase. The collection consists of 10,000 utility-first NFT membership passes that double as unique digital collectibles minted on the Solana blockchain. Each Stargate NFT will grant you access to member-only bars and lounges, exclusive airdrops, and future Stargate and Sidechain rewards.<br /><br />Proceeds from mint and resale royalties will open high-end <span className="af-class-bolditalic_span">Stargates</span>, bars and lounges exclusive to our NFT holders. Members will also join the Stargate DAO, where they will vote on topics such as future benefits, royalty decisions, etc.</p>
                    </div>
                    <div className="af-class-column af-class-is--starshipimages"><img src="images/starship1.jpg" loading="lazy" style={{display: 'block'}} data-w-id="5e3c6e1e-b99b-2069-43e9-2491716e1190" srcSet="images/starship1-p-500.jpeg 500w, images/starship1.jpg 712w" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" alt className="af-class-starship_image" /><img src="images/starship2.jpg" loading="lazy" style={{display: 'block'}} data-w-id="5bf52e4a-644a-4c63-cf6f-31a48ac8a3ba" srcSet="images/starship2-p-500.jpeg 500w, images/starship2.jpg 712w" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" alt className="af-class-starship_image af-class-_2" /><img src="images/starship3.jpg" loading="lazy" style={{display: 'block'}} data-w-id="ec9de125-a652-9ed6-d076-d9083faea422" srcSet="images/starship3-p-500.jpeg 500w, images/starship3.jpg 712w" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" alt className="af-class-starship_image af-class-_3" /><img src="images/starship4.jpg" loading="lazy" style={{display: 'block'}} data-w-id="91a8cb2a-d712-3d34-a99f-6412d18f2cc5" srcSet="images/starship4-p-500.jpeg 500w, images/starship4.jpg 712w" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" alt className="af-class-starship_image af-class-_4" /><img src="images/starship5.jpg" loading="lazy" style={{display: 'block'}} data-w-id="bdfd2cc2-370e-1056-65c1-1c59a7e7f02c" srcSet="images/starship5-p-500.jpeg 500w, images/starship5.jpg 712w" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" alt className="af-class-starship_image af-class-_5" /><img src="images/starship6.jpg" loading="lazy" style={{display: 'block'}} data-w-id="f695a95c-536a-4ca6-7b07-2ed83db8e54d" srcSet="images/starship6-p-500.jpeg 500w, images/starship6.jpg 712w" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" alt className="af-class-starship_image af-class-_6" /></div>
                  </div>
                  <div data-w-id="20c67cd4-7095-7ce2-2ee3-477ee9f81dd8" style={{opacity: 0}} className="af-class-column af-class-is--distributionprocess">
                    <div className="af-class-is_16pxbp">
                      <h2>Distribution Process</h2>
                    </div>
                      <p>Stargate NFT price is <strong>2 SOL</strong>. Out of the 10,000 maximum Stargate NFTs, 8,000 will be available at mint date and the remaining 1,500 will be pooled as earnable rewards on our Sidechain platform. 500 are allocated to founders and giveaways.<br /><br />We are setting aside these 1,500 earnable Stargate NFTs to ensure future accessibility to this collection. However, earning one through the <a href="//sidechainme.com/about" className="af-class-paragraph_link">Sidechain platform</a> is competitive and time consuming.<br /><br /><strong>None of the net proceeds will be distributed or profited by founders.</strong></p>
                  </div>
                  <div className="af-class-line" />
                </div>
              </div>
              <div className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--technicals">
                  <div className="af-class-grid af-class-is--technicals">
                    <div data-w-id="96ed7c80-05c7-7159-75b7-10fe25f90930" style={{opacity: 0}} className="af-class-column af-class-is--technicalscontent">
                      <div className="af-class-is_29pxbp">
                        <h2>Technicals</h2>
                      </div>
                      <p>Each Stargate NFT is unique and algorithmically generated from over 70 traits including starship type, terrain, atmosphere, add-ons, and more.<br /><br />Stargate NFTs are stored as tokens on the Solana blockchain and only 1 NFT can be minted per transaction.<br /><br />Proceeds generated from the mint and subsequent secondary royalties will be used to build and operate Stargates, fund membership benefits, and achieve short &amp; long-term roadmap goals. We are building to further the use cases for cryptocurrencies and integrate new blockchain applications into everyday life.</p>
                    </div>
                    <div data-w-id="96ed7c80-05c7-7159-75b7-10fe25f9093c" style={{opacity: 0}} className="af-class-column af-class-is--technicalimageblock"><img src="images/technical-image.jpg" loading="lazy" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" srcSet="images/technical-image-p-500.jpeg 500w, images/technical-image.jpg 712w" alt className="af-class-technicals_image" /></div>
                  </div>
                  <div className="af-class-line af-class-is--technicals" />
                </div>
              </div>
              <div id="benefits" className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--benefits">
                  <div className="af-class-is_10pxbp">
                    <h2 data-w-id="41659fc1-02cd-4ce8-bee0-170fe90c2e70" style={{opacity: 0}}>The Stargate Benefits</h2>
                  </div>
                  <p data-w-id="ae64ece0-1d4a-ce4b-e113-33a83cca8d51" style={{opacity: 0}}>Stargate NFTs act as your exclusive membership passes to physical Stargate locations and give extremely valuable rewards beyond being a collectible and elite piece of digital art. <strong>Your membership benefits increase as you collect the entire fleet of 7 Starships types.</strong></p>
                  <div className="af-class-grid af-class-is--benefits">
                    <div className="af-class-column af-class-is--benefit"><img src="images/benefit-icon-1.svg" loading="lazy" alt className="af-class-benefit_icon" />
                      <div className="af-class-benefit_text">Ownership and commercial use rights given to the owner of their NFT</div>
                    </div>
                    <div className="af-class-column af-class-is--benefit"><img src="images/benefit-icon-2.svg" loading="lazy" alt className="af-class-benefit_icon" />
                      <div className="af-class-benefit_text">Access to physical Stargate member-only bars, lounges, and events</div>
                    </div>
                    <div className="af-class-column af-class-is--benefit"><img src="images/benefit-icon-3.svg" loading="lazy" alt className="af-class-benefit_icon" />
                      <div className="af-class-benefit_text">Free or discounted passes to Sidechain partnered music festivals, bars, and clubs</div>
                    </div>
                    <div className="af-class-column af-class-is--benefit"><img src="images/benefit-icon-4.svg" loading="lazy" alt className="af-class-benefit_icon" />
                      <div className="af-class-benefit_text">Access to member-only Stargate merchandise</div>
                    </div>
                    <div className="af-class-column af-class-is--benefit"><img src="images/benefit-icon-5.svg" loading="lazy" alt className="af-class-benefit_icon" />
                      <div className="af-class-benefit_text">Continuous benefits and rewards as the platform grows, including planned airdrops of Pilots</div>
                    </div>
                  </div>
                  <div className="af-class-line af-class-is--benefits" />
                </div>
              </div>
              <div className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--union">
                  <div className="af-class-grid af-class-is--union">
                    <div data-w-id="f38ed52b-9b3b-eaaf-73a0-71b206a561d3" style={{opacity: 0}} className="af-class-column af-class-is--unioncontent">
                      <div className="af-class-is_16pxbp">
                        <h2>Physical and digital Union</h2>
                      </div>
                      <p>Stargate NFTs are designed to propel the utility of blockchain technology beyond digital space. Early adopters and believers will be the first to experience new social and payment innovations fueled by their Stargate memberships.<br /><br />The net proceeds from primary and secondary Stargate NFT sales will be used to continuously fund the development and operations of bars, lounges, events, and more.<br /><br />For example, at Stargate bars, all transactions will be made in SOL, LED screens will dynamically change to the rarest holder’s NFT color scheme, and discounts will be earned for holding one of each Starship type.</p>
                    </div>
                    <div data-w-id="f38ed52b-9b3b-eaaf-73a0-71b206a561df" style={{opacity: 0}} className="af-class-column af-class-is--unionimageblock"><img src="images/union-image.jpg" loading="lazy" alt className="af-class-union_image" /></div>
                  </div>
                  <div className="af-class-line" />
                </div>
              </div>
              <div id="roadmap" className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--roadmap">
                  <div data-w-id="b2ca6255-4925-dbe8-9977-1f6e47043135" style={{opacity: 0}} className="af-class-column af-class-is--roadmaphero">
                    <div className="af-class-is_8pxbp">
                      <h2>Roadmap</h2>
                    </div>
                    <p>With each NFT minted, buyers are investing into a completely new NFT experience. We are confident in the long-term value and utility we’ll provide to those who join and become the premier venues for those who are going to make it.</p>
                  </div>
                  <div className="af-class-grid af-class-is--roadmap">
                    <div data-w-id="8d0379fc-f4cd-4396-5b75-ae84ee23de7c" style={{opacity: 0}} className="af-class-column af-class-is--roadmapcontent">
                      <div className="af-class-roadmap_flex">
                        <div className="af-class-roadmap_number_column">
                          <div className="af-class-roadmap_number">5%</div>
                        </div>
                        <div className="af-class-roadmap_text_block">
                          <div className="af-class-roadmap_text">We cover our expenses and begin searching for Stargate locations</div>
                        </div>
                      </div>
                      <div className="af-class-roadmap_flex">
                        <div className="af-class-roadmap_number_column">
                          <div className="af-class-roadmap_number">10%</div>
                        </div>
                        <div className="af-class-roadmap_text_block">
                          <div className="af-class-roadmap_text">Stargate member-only merch store unlocked, featuring limited edition apparel</div>
                        </div>
                      </div>
                      <div className="af-class-roadmap_flex">
                        <div className="af-class-roadmap_number_column">
                          <div className="af-class-roadmap_number">60%</div>
                        </div>
                        <div className="af-class-roadmap_text_block">
                          <div className="af-class-roadmap_text">Stargate Auto Battler PvP Game</div>
                        </div>
                      </div>
                      <div className="af-class-roadmap_flex">
                        <div className="af-class-roadmap_number_column">
                          <div className="af-class-roadmap_number">80%</div>
                        </div>
                        <div className="af-class-roadmap_text_block">
                          <div className="af-class-roadmap_text">Airdrop Stargate Pilots to holders of Stargate Starship NFTs</div>
                        </div>
                      </div>
                      <div className="af-class-roadmap_flex">
                        <div className="af-class-roadmap_number_column">
                          <div className="af-class-roadmap_number">100%</div>
                        </div>
                        <div className="af-class-roadmap_text_block">
                          <div className="af-class-roadmap_text">Equip Stargate bars and lounges with robotic bartenders and baristas</div>
                        </div>
                      </div>
                      <div className="af-class-roadmap_flex af-class-is--nomargin">
                        <div className="af-class-roadmap_number_column">
                          <div className="af-class-roadmap_number">100%+</div>
                        </div>
                        <div className="af-class-roadmap_text_block">
                          <div className="af-class-roadmap_text">Royalties will be used to upgrade Stargates and expand to other cities</div>
                        </div>
                      </div>
                    </div>
                    <div data-w-id="444bd9d8-f777-881e-7051-244874a90e09" style={{opacity: 0}} className="af-class-column af-class-is--roadmapimage"><img src="images/roadmap-image.jpg" loading="lazy" sizes="(max-width: 767px) 92vw, (max-width: 991px) 26vw, 23vw" srcSet="images/roadmap-image-p-500.jpeg 500w, images/roadmap-image.jpg 650w" alt className="af-class-roadmap_image" /></div>
                  </div>
                  <div className="af-class-line af-class-is--roadmap" />
                </div>
              </div>
              <div id="team" className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--team">
                  <div className="af-class-grid af-class-is--team">
                    <div data-w-id="a9d82193-013c-0510-bf75-3d5be82f0a9e" style={{opacity: 0}} className="af-class-column af-class-is--teamcontent">
                      <div className="af-class-is_6pxbp">
                        <h2>The Team</h2>
                      </div>
                      <p>Stargate was created by friends who want to push the boundaries of utility NFTs. We want to redefine social experiences using blockchain technology and give members real places to connect and share. Our backgrounds in finance, technology, and art within the live event space make us a uniquely qualified team. We believe anonymity in Web3 is important. But not for the team you invest in.</p>
                      <div className="af-class-team_members_wrap">
                        <p className="af-class-teammember_info"><span className="af-class-membername">Gavin</span> - Co-Founder of Stargate &amp; Sidechain</p>
                        <p className="af-class-teammember_info"><span className="af-class-membername">Matt</span> - Co-Founder of Stargate &amp; Sidechain</p>
                        <p className="af-class-teammember_info"><span className="af-class-membername">Katie</span> - Stargate NFT Artist</p>
                      </div>
                    </div>
                    <div data-w-id="a9d82193-013c-0510-bf75-3d5be82f0aaa" style={{opacity: 0}} className="af-class-column af-class-is--teamimageblock"><img src="images/team-section-image.jpg" loading="lazy" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" srcSet="images/team-section-image-p-500.jpeg 500w, images/team-section-image.jpg 712w" alt className="af-class-team_image" /></div>
                  </div>
                  <div className="af-class-line af-class-is--team" />
                </div>
              </div>
              <div id="faq" className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--faq">
                  <div className="af-class-grid af-class-is--faq">
                    <div data-w-id="433e8308-7d9b-6733-0199-1c88795360bf" style={{opacity: 0}} className="af-class-column af-class-is--faqcontent">
                      <div className="af-class-is_22pxbp">
                        <h2>FAQ</h2>
                      </div>
                      <div className="af-class-faq_wrap af-class-is--1">
                        <div className="af-class-is_6pxbp">
                          <p className="af-class-question">When is the mint?</p>
                        </div>
                          <p className="af-class-answer">The whitelist mint date is <strong>Friday, April 15, 2022.</strong><br /> The public mint date is <strong>Saturday, April 16, 2022.</strong><br /> To keep up to date, follow our Twitter and Discord.</p>
                      </div>
                      <div className="af-class-faq_wrap">
                        <div className="af-class-is_6pxbp">
                          <p className="af-class-question">What is the price of a Stargate?</p>
                        </div>
                          <p className="af-class-answer">The mint price is <strong>2 SOL</strong>. We want to optimize for affordability while being able to fund our promised roadmap. We’ve contemplated the idea of increasing the supply to maintain affordability and accessibility for the mint.</p>
                      </div>
                      <div className="af-class-faq_wrap af-class-is--small">
                        <div className="af-class-is_6pxbp">
                          <p className="af-class-question">How are you utility-first and where will the net proceeds go?</p>
                        </div>
                        <p className="af-class-answer">Opening up Stargate bars and lounges will be expensive and require uprfront overhead capital for operations. <br /><br />All proceeds will be directly invested into Stargate infrastructure. We’re trying to go beyond your typical PFP project and become a DAO that disrupts the centralized models seen in the world today. The truth is, if we’re going to make blockchain a reality and compete with centralized corporations, we need capital to grow quickly. We aren’t scared to ask for a higher price because we understand the utility and value that we’ll deliver. A project with goals like ours cannot succeed from secondary royalties alone.<br /><br />Buying a Stargate NFT is investing in real world blockchain applications of the future. Minters will receive future value far beyond the initial cost to mint our NFT.</p>
                      </div>
                      <div className="af-class-faq_wrap">
                        <div className="af-class-is_6pxbp">
                          <p className="af-class-question">What is a physical Stargate bar and lounge?</p>
                        </div>
                        <p className="af-class-answer">Permanent, high-tech venues where members can meet up, drink, and chill. Transactions and payments such as buying food, drinks, or guest passes will be made in SOL only.<br /><br />Your Stargate NFT pass will vary in rarity. The rarer the Stargate NFT, the more choices you can make such as music requests, LED lighting panels to reflect your specific NFT art, number of guests you can bring, and discounts per visit. <br /><br />We’re going to go all out to give our Stargate members the most innovative next-generation experiences possible.</p>
                      </div>
                    </div>
                    <div data-w-id="0b31a929-3db8-fb53-22fb-f8b89aa92b98" style={{opacity: 0}} className="af-class-column af-class-is--faqcontent2">
                      <div className="af-class-column af-class-is--faqimageblock"><img src="images/faq-section-image.jpg" loading="lazy" sizes="(max-width: 767px) 92vw, (max-width: 991px) 28vw, 25vw" srcSet="images/faq-section-image-p-500.jpeg 500w, images/faq-section-image.jpg 712w" alt className="af-class-faq_image" /></div>
                      <div className="af-class-is_6pxbp">
                        <p><strong>What is this Sidechain platform mentioned?</strong></p>
                      </div>
                      <p className="af-class-paragraph-small">Sidechain is our Web3 platform that aims to introduce the public to Solana and blockchain use cases through music events.<br /><br />Sidechain partners with bars, clubs, and music festivals to offer Solana blockchain ticketing, rewards, and NFTs to attendees. Users will be able to earn Sidechain reward points through attending partnered events then redeem their points for Stargate NFT memberships.<br /><br />As more music festivals and venues partner with Sidechain, Stargate membership benefits will increase. Members will receive passes and discounts to events, venues, and tickets in the Sidechain network.<br /><br />Therefore, by being an early adopter and member, your investment into a Stargate NFT will return value overtime as the flywheel hits widespread use. <br /><br />You can learn more about Sidechain <a href="//sidechainme.com/about" className="af-class-faq_link">here</a>.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-wf-section">
                <div className="af-class-container af-class-is--footer">
                  <div data-w-id="97fee326-d7a9-6238-6ba1-7dce6a45c8e5" style={{opacity: 0}} className="af-class-footer_sociallinks_block">
                    <a href="//discord.gg/uErVGvQZt8" className="af-class-social_link w-inline-block"><img src="images/discord-logo.png" loading="lazy" alt className="af-class-social_icon" /></a>
                    <a href="//twitter.com/StargateDAO" className="af-class-social_link w-inline-block"><img src="images/twitter-logo.png" loading="lazy" alt className="af-class-social_icon" /></a>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */