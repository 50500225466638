import { BrowserRouter, Routes, Route } from "react-router-dom";

import IndexView from './views/IndexView';
import DreamworldView from './views/DreamworldView';
import NotFoundView from  './views/NotFoundView';

let StargateRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<IndexView />}/>
                <Route path="/dreamworld" element={<DreamworldView />}/>
                <Route path="*" element={<NotFoundView />} />
            </Routes>
        </BrowserRouter>
    );
};

export default StargateRoutes;